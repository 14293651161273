var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('safesWithdrawals.safesWithdrawals'),"description":_vm.$t('safesWithdrawals.fromHereYouCanControlSafesWithdrawals')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('safesWithdrawals.searchForWithdrawals'),"emptyTableText":_vm.$t('safesWithdrawals.thereAreNoWithdrawalsOperations'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.safes_withdrawals_add,"statusSearch":true,"statusValues":[
                      { title: _vm.$t('orders.situation'), value: ''},
                      { title: _vm.$t('orders.all'), value: ''},
                      { title: _vm.$t('invoices.draft'), value: 0 },
                      { title: _vm.$t('invoices.approved'), value: 1 } ],"cloumns":[
                       {column: 'code',    title: _vm.$t('safesWithdrawals.operation') ,type: 'mainLink' , sort: true },
                       {column: 'safe_id',    title:_vm.$t('safesWithdrawals.safe'),      type: 'link', to:'safe', sort: true, link: true},
                       {column: 'date',    title: _vm.$t('safesWithdrawals.withdrawalsDate') ,type: 'text' , sort: true },
                       {column: 'description',    title: _vm.$t('safesWithdrawals.thatsAbout') ,type: 'text' , sort: true },
                       {column: 'cost',    title: _vm.$t('safesWithdrawals.amount') ,type: 'text' , sort: true },
                       {column: 'status', title: _vm.$t('bills.situation') ,type: 'status' , sort: true ,  values: [
                            {title: _vm.$t('invoices.draft'), value: 0, color: 'dark'},
                            {title: _vm.$t('invoices.approved'), value: 1, color: 'success'} ]
                       },
                       {column: 'options', title: _vm.$t('safesWithdrawals.settings'), type: 'options', options: [
                           {name: 'show'},
                           {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.safes_withdrawals_edit},
                           {name: 'file'},
                           {name: 'printSafesWithdrawal',   role: true},
                           {name: 'delete', role: _vm.$user.admin || _vm.$user.role.safes_withdrawals_delete} ]}
                     ],"deleteText":{
                      attention: _vm.$t('allerts.Attention'),
                      areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                      close: _vm.$t('allerts.close'),
                      confirm: _vm.$t('allerts.confirm'),
                      loading: _vm.$t('allerts.loading'),
                     }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }